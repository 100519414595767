.checkbox{
	&-info{
		.form-check-input{
			&:focus{
				border-color: $info;
				outline: 0;
			}
			&:checked{
				background-color: $info;
				border-color: $info;
			}
		}
	}
	&-danger {
		.form-check-input{
			&:focus{
				border-color: $danger;
				outline: 0;
			}
			&:checked{
				background-color: $danger;
				border-color: $danger;
			}
		}
	}
	&-success{
		.form-check-input{
			&:focus{
				border-color: $success;
				outline: 0;
			}
			&:checked{
				background-color: $success;
				border-color: $success;
			}
		}
	}
	&-warning{
		.form-check-input{
			&:focus{
				border-color: $warning;
				outline: 0;
			}
			&:checked{
				background-color: $warning;
				border-color: $warning;
			}
		}
	}
	&-secondary{
		.form-check-input{
			&:focus{
				border-color: $secondary;
				outline: 0;
			}
			&:checked{
				background-color: $secondary;
				border-color: $secondary;
			}
		}
	}
}
.form-check-input:checked{
	background-color: var(--primary);
}