.coming-soon {
	height: 100vh;
	min-height: 700px;
    width: 100%;
    background-size: cover;
    background-position: center;
	background-color:#000;
	text-align:center;
    z-index: 1;
	overflow:hidden;
    position: relative;
	display: flex;
    align-items: center;
    justify-content: center;
	
	.logo-header img {
		max-width: 250px;
	}
	
	@include respond('tab-land'){
		min-height: 550px;
	}
	@include respond('phone-land'){
		padding-top:30px;
		min-height: 500px;
	}
	.inner-content {
		padding-bottom: 100px;
		@include respond('tab-port'){
			padding-bottom: 50px;
		}
		@include respond('phone-land'){
			padding-bottom: 30px;
		}
	}
	.dz-head {
		font-size: 42px;
		margin-bottom: 10px;
		
		@include respond('tab-land'){
			font-size: 42px;
			margin-bottom: 10px;
		}
		@include respond('tab-port'){
			font-size:35px;
		}
		@include respond('phone-land'){
			font-size:30px;
		}
		@include respond('phone'){
			font-size: 28px;
		}
	}
	svg {
		position:absolute;
		left:0;
		width:100%;
		bottom:0;
		path {
			fill:var(--primary);
		}
	}
	p {
		font-size:20px;
	    max-width: 530px;
	    margin: 0;
	    margin: auto;
		@include respond('tab-land'){
			font-size:17px;	
		}
		@include respond('tab-port'){
			font-size:15px;	
		}
	}
	.countdown {
		margin-bottom: 40px;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		
		.date {
			margin: 0 10px;
			display: inline-block;
			border-radius: 0;
			
			.skew{
				position: relative;
			}
			span{
				display: block;
				font-weight: 500;
				color: var(--secondary);
				border-radius: 6px;
				padding: 2px;
				text-transform: uppercase;
				letter-spacing: 1px;

				@include respond('tab-port'){
					font-size:14px;
				}
			}
			.time{
				margin:0;
				font-weight: 700;
				font-size: 82px;
				line-height: 82px;
			}
			@include respond('tab-port'){ 
				.time {
					font-size: 75px;
				}
			}
			@include respond('phone'){
				margin: 0 5px;
				.time{
					font-size: 35px;
					line-height: 1;
				}
			}
		}
		@include respond('tab-land'){
			margin:30px 0;
		}
		@include respond('phone'){
			max-width:340px;
			margin-top: 25px;
			margin-bottom: 25px;
		}
	}
	.logo-header {
		width:100%;
		height:auto;
		margin-bottom: 30px;
		
		@include respond('phone'){
			margin-bottom: 20px;
		}
		@include respond('tab-port'){
			margin-bottom: 15px;
		}
		
	}
	&:after {
		content: attr(data-text);
	    position: absolute;
	    font-weight: 700;
	    font-size: 248px;
	    line-height: 1;
	    font-family: var(--font-family-title);
	    z-index: -2;
	    left: 0px;
	    color: #fff;
	    filter: drop-shadow(0px 10px 40px rgba(255, 129, 57, 0.3));
	    opacity: 0.3;
	    bottom: 0%;
	    @include respond('tab-port'){
			font-size: 200px;
		    bottom: 5%;
		}
		@include respond('phone-land'){
			font-size: 150px;
		}
		@include respond('phone'){
			font-size: 90px;
		    bottom: 2%;
		}
	}
	
	
	.shape1,
	.shape2,
	.shape3,
	.shape4,
	.shape5,
	.girl-img{
		position: absolute;
	}
	.shape1{
		left: -20%;
		top: -50%;
	}
	.shape2{
		right: -20%;
		bottom: -50%;
	}
	.shape3{
		right: 5%;
		top: 10%;
	}
	.shape4{
		left: 15%;
		top: 30%;
		z-index: -1;
	}
	.shape5{
		left: 50%;
		bottom: -20px;
		z-index: -1;
	}
	.girl-img{
		left: 0;
		bottom: 0;
		z-index: 1;
	}
	@include respond('tab-land'){
		.girl-img{
			width: 250px;
		}
		.shape1{
			left: -10%;
			top: -10%;
			width: 300px;
		}
		.shape2{
			right: -10%;
			bottom: -10%;
			width: 300px;
		}
		.shape3,
		.shape4,
		.shape5{
			width: 50px;
		}
	}
	@include respond('phone'){
		.girl-img{
			width: 200px;
		}
		.shape1{
			left: -30%;
			top: -20%;
			width: 250px;
		}
		.shape2{
			right: -30%;
			bottom: -20%;
			width: 250px;
		}
	}
}

.countdown-timer{
	.items-days{
		width: 120px;
		height: 120px;
		position: relative;
		margin-bottom: 10px;
		
		@include respond('phone-land'){
			width: 80px;
			height: 80px;
		}
		@include respond('phone'){
			width: 65px;
			height: 65px;
		}
		.val{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-weight: 600;
			color: #000;
			font-size: 36px;
			
			@include respond('phone-land'){
				font-size: 20px;
				margin-bottom: 5px;
			}
			@include respond('phone'){
				font-size: 18px;
			}
		}
	}
	.kineticjs-content:after{
		content: "";
		position: absolute;
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-radius: 100%;
		top: 2px;
		bottom: 2px;
		left: 2px;
		right: 2px;
		z-index: -1;
	}
}

.inquiry-modal {
	padding: 0 15px;	
	.modal-dialog {
		max-width: 700px;
		display: flex;
		min-height: auto;
		background-color: #fff;
		align-items: stretch;
		border-radius: var(--border-radius-base);
		overflow: hidden;
	}
	.inquiry-adv{
		flex: 0 0 50%;
		max-width: 50%;
		
		img{
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
	.dz-ajax-overlay:after{
		transform: unset;
	}
	.modal-content {
		border: 0;
		border-radius: 0;
		background: #fff;
		padding: 30px;
		flex: 0 0 50%;
		max-width: 50%;
		
		.modal-header {
			border: 0;
			padding: 0;
			display: block;
			text-align: center;
			
			i {
				color: var(--primary);
				font-size: 40px;
				line-height: 1;
				display: inline-block;
			}
		}
		.modal-title{
			font-size: 24px;
			margin-bottom: 25px;
			line-height: 1.3;
			font-family: var(--font-family-title);
			text-align: center;
			width: 100%;
		}
		.modal-body {
			padding: 0;
		}
	}
	.btn-close {
		margin: 0;
		border: 0;
		right: 5px;
		top: 0px;
		opacity: 1;
		padding: 0;
		height: 40px;
		width: 40px;
		font-size: 40px;
		font-weight: 100;
		text-shadow: none;
		line-height: 40px;
		position: absolute;
		background: transparent;
		color: var(--theme-text-color);
	}
	@include respond('phone-land'){
		.inquiry-adv {
			display: none;
		}
		.modal-content {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 20px;
		}
	}
}