// Testimonial 1
.testimonial-2 {
	.testimonial-pic{
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: 0;
		
		img{
			height: 100%;
			object-fit: cover;
		}
	}
	.testimonial-info{
		.quote{
			font-size: 2rem;
			color: var(--primary);
		}
		.testimonial-text{
			font-size: 19px;
			font-weight: 400;
			color: #676767;
			
		@include respond('tab-land'){
		font-size: 1rem;
		}
		}
		.testimonial-name{
			font-size: 24px;
			font-weight: 500;
		}
		.testimonial-position{
			font-size: 1rem;
			font-weight: 500;
		}
	}

	@include respond('tab-port'){
		.testimonial-pic{
			height: 100px;
			width: 100%;
			text-align: center;
			margin: 0;
			img{
				border-radius: 50%;
				border: 5px solid var(--rgba-primary-3);
			}
		}
		.testimonial-info{
			text-align: center;
		}
	}
}

.testimonial-swiper-wrapper-3{

	.dz-swiper-next3,
	.dz-swiper-prev3{
	position: absolute;
	top: 60%;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 0px;
    color: var(--theme-text-color);
    background: var(--primary);
    font-size: 15px;
    z-index: 1;
    text-align: center;
    margin: 0 10px;
    display: inline-block;
    transform: skewX(-12deg);
    box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;

	&:hover{
		background: #000;
		
	}
	@include respond('phone'){
		top: 40%;
	}
}	
	.dz-swiper-next3{
		right: 10%;
		@include respond('tab-land'){
			right: 2%;
		}
	}
	.dz-swiper-prev3{
		left: 10%;
		
		@include respond('tab-land'){
			left: 2%;
		}
	}
}