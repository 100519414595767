.process-box{
    &.style-1{
        padding: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        @include transitionMedium;
      
        .dz-info{
            display: none;
        }
        &:hover{
            display: inline-block;
            
        }
		.step{
            font-weight: 275;
            font-size: 60px;
            color: #232323;
            line-height: unset;
            position: relative;
            z-index: 10;
            span{
                font-size: 1rem;
                color: var(--primary);
                font-weight: 500;
            }
        }     
        .dz-title{
            font-size: 30px;
            font-weight: 500!important;
            color: #232323;
            margin:0;
        }
        .descripiton{
            font-size: 1rem;
            font-weight: 300;
            color: #666666;
            margin-bottom: 0;
            @include transitionMedium;
        }
        @include respond('tab-port'){
            .step{
                font-weight: 500;
            }
            .dz-title{
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 0.4rem;
            }
            .descripiton{
                font-size: 14px;
            }
        }
		@include respond('phone'){
			padding: 15px 0 30px;
		}
    }
}

.dz-box.style-3 .dz-info {
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    padding: 20px;
}
.image-tooltip.style-2{
    padding: 0!important;
}