.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
}
.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}
.ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}