// Banner One
.main-bnr-three{
	position:relative;
  	align-items: center;
	z-index:1;
	overflow: hidden;
	
	&::after{
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--gradient-3);		
		backdrop-filter: blur(5px);
		opacity: 0.75;
		z-index: -1;
	}
	.banner-inner{
		display: flex;
		padding-top: 180px;
		min-height: 960px;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		
		@include respond('laptop'){
			min-height: 800px;	
		}	
		@include respond('tab-land'){
			min-height: 720px;
		}
		@include respond('tab-port'){
			min-height: 600px;
		}
		@include respond('phone'){
			min-height: 500px;
		}
		
		.move-1{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
		.data-text{
			position: absolute;		
			color: #fff;
			font-weight: 700;
			font-size: 330px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 80%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			top: 32%;
			left: 0;
			bottom: auto;
			text-shadow: 0px -15px 50px var(--rgba-primary-1);
			width: 100%;
			display: flex;
			justify-content: center;
			opacity: 1;
			
			span{
				animation: none;
			}
			@include respond('laptop'){
				font-size: 250px;
				top: 28%;
			}
			@include respond('tab-land'){
				font-size: 200px;				
			}
			@include respond('tab-port'){
				font-size: 150px;				
			}
			@include respond('phone-land'){
				font-size: 80px;
			}
		}
		.banner-media{
			text-align: center;
			z-index: 2;
			position: relative;
			max-width: 100%;
			width: 90%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			
			img{
				width: 615px;
				
				@include respond('laptop') {
					width: 520px;
				}
				@include respond('tab-land'){
					width: 450px;
				}
				@include respond('tab-port'){
					width: 380px;
				}
				@include respond('phone'){
					width: 280px;
				}
			}
		}
		.banner-content{
			position: relative;
			z-index: 10;
			height: 80%;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			
			.title{
				font-style: italic;
				font-weight: 700;
				font-size: 153px;
				letter-spacing: 0.005em;
				color: #FFFFFF;
				text-shadow: 0px 30px 80px rgba(0, 0, 0, 0.2);
				
				@include respond('laptop') {
					font-size: 120px;
				}
				@include respond('tab-land'){
					font-size: 90px;
				}
				@include respond('tab-port'){
					font-size: 70px;
				}
				@include respond('phone-land'){
					font-size: 50px;
				}
				@include respond('phone'){
					font-size: 36px;
				}
				.left{
					position: absolute;
					top: 0;
					left: 80px;
					
					@include respond('tab-land'){
						top: 0px;
						left: 150px;
					}
					@include respond('tab-port'){
						left: 80px;
					}
					
					@include respond('phone-land'){
						left: 80px;
					}
					@include respond('phone'){
						left: 30px;
					}
					
				}
				.right{
					position: absolute;
					bottom: 18%;
					right: -120px;
					
					@include respond('laptop'){
						right: -60px;
					}
					@include respond('tab-land'){
						bottom: 20%;
						right: -30px;
					}
					@include respond('tab-port'){
						right: 0px;
						bottom: 10%;
					}
					@include respond('phone-land'){
						bottom: 18%;
					}
					@include respond('phone'){
						right: -10px;	
					}
				}
			}
			.bottom-content{
				margin-left: -20px;
				position: relative;	
				padding-top: 20px;
				
				@include respond('phone'){
					display: none;
				}	
				p{
					color: #fff;
					font-weight: 400;
					font-size: 1rem;
					line-height: 1.6;
					
					@include respond('tab-port'){
						font-size: 14px;
					}
				}
				&::after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 80%;
					height: 4px;
					background: white;
					border-radius: 5px;
				}
			}
		}
	}
}