.icon-bx-wraper{
	// Style 1
	&.style-1{
		padding: 30px;
		@include transitionMedium;
		margin-top: 40px;
		margin-bottom: 15px;
		z-index: 1;
		
		&:after{
			content: "";
			height: 100%;
			width: 100%;
			background-color: var(--rgba-primary-1);
			@include transitionMedium;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			transform: skewY(-5deg);
		}
	
		.icon-bx {
			height: 120px;
			width: 120px;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px -10px 50px 0px var(--rgba-primary-2);
			margin-top: -70px;
		}
		.icon-content {
			overflow: visible;
		}
		
		&:hover,
		&.active{
			color: var(--theme-text-color);
			
			.dz-title,
			.dz-title a{
				color: var(--theme-text-color);
			}
			.icon-cell {
				animation: move-lr 1.2s linear;
			}
			.btn {
				background-color:#fff;
				color: #000;
			}
			
			&:after{
				background-color: var(--primary);
				box-shadow: 0px 10px 30px 0px rgba(255, 127, 57, 0.2);
			}
		}
		@include respond('tab-port'){
			.icon-bx {
				height: 105px;
				width: 105px;
				margin-top: -60px;
				img {
					width: 50px;
				}
			}
		}
		@include respond('phone'){
			padding: 25px;
			.icon-bx {
				height: 95px;
				width: 95px;
				margin-top: -50px;
				img {
					width: 45px;
				}
			}
		}

	}
	
	// Style 2
	&.style-2{
		padding: 15px 15px 15px 25px;
		position: relative;
		background: white;
		transform: skewX(-12deg);
	    display: flex;
	    align-items: center;
	    box-shadow: 0px 10px 50px 0px rgba(0, 35, 55, 0.1);
		
		&:after {
			content: '';
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 7px;
		    height: 100%;
		    background: var(--primary);
		    opacity: 0.3;
			@include transitionMedium;
		}
		.icon-bx{
		    margin-right: 15px;
			transform: skewX(12deg);
			height: 35px;
			width: 35px;
			min-width: 35px;
		}
		.icon-content{
			transform: skewX(12deg);
			.dz-title{
				color: #000;
				font-weight: 500;
				font-size: 18px;
				margin-bottom: 0;
			}
		}
		&:hover {
			&:after {
		    	opacity: 1;
			}
		}
		@include respond ('phone'){
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	// Style 3
	&.style-3 {
		padding: 30px;
		background-color: var(--secondary);
		@include transitionMedium;
		color:#fff;
		z-index: 1;
		position: relative;
		overflow: hidden;
		
		.icon-bx{
			display: flex;
		}
		.icon-cell {
			display: inline-block;
			i{
				font-size: 60px;
				line-height: 1;
				display: flex;
				@include transitionMedium;
			}
			img{
				@include transitionMedium;
			}
		}
		.icon-content {
			overflow: visible;
		}
		&:after{
			content:"";
			position: absolute;
			display: inline-block;
			background-color: var(--primary);
			width: 0%;
			height: 100%;
			top: 0;
			z-index: -1;
			right: 0%;
		    transform-origin: right center;
			@include transitionMedium;
		}
		.dz-title,
		.dz-title a{
			color:#fff;
			font-size: 28px;
			font-weight: 400;
			@include respond('tab-port'){
				font-size: 24px;
			}
		}
		p {
			font-size: 15px;
			color: #E0E0E0;
			@include transitionMedium;
		}
		.read-more {
			color: var(--primary);
			letter-spacing: 2px;
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
			font-family: var(--font-family-title);
			@include transitionMedium;
		}
		&:hover,
		&.active{
			color: var(--theme-text-color);
			
			p,
			.dz-title,
			.dz-title a,
			.read-more{
				color: var(--theme-text-color);
			}
			.icon-cell {
				display: inline-block;
				animation: move-lr 1.2s linear;
				i{
					color: var(--theme-text-color)
				}
				img{
					filter: brightness(0);
				}
			}
			.btn {
				background-color:#fff;
				color: #000;
			}
			
			&:after{
				left: 0;
			    width: 100%;
			    transform-origin: left center;
			}
		}
		@include respond('tab-port'){
			.icon-bx {
				img {
					width: 50px;
				}
			}
		}
		@include respond('phone'){
			padding: 25px;
			.icon-bx {
				img {
					width: 45px;
				}
			}
		}
	}

	&.style-4{
		padding: 30px;
		background-color: var(--secondary);
		@include transitionMedium;
		color:#000;
		z-index: 1;
		position: relative;
		overflow: hidden;
		box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
		
		&::before{
			content: '';
			position: absolute;
			top: 6px;
			right: 6px;
			border-top: 10px solid var(--primary);
			border-right: 10px solid var(--primary);
			border-bottom: 10px solid transparent;
			border-left: 10px solid transparent;
		}
		.icon-bx{
			display: flex;
			
			&.icon-bg-white{
				.dz-title{
					margin: 0;
				}
				.icon-cell{
					padding: 10px;
					background: white;
					
					i{
						font-size: 30px;
					}
				}
			}
		}
		.icon-cell {
			
			display: inline-block;
			i{
				font-size: 60px;
				line-height: 1;
				display: flex;
				@include transitionMedium;
			}
			img{
				@include transitionMedium;
			}
		}
		.icon-content {
			overflow: visible;
		}
		&:after{
			content:"";
			position: absolute;
			display: inline-block;
			background-color: var(--secondary);
			width: 0%;
			height: 100%;
			top: 0;
			z-index: -1;
			right: 0%;
		    transform-origin: right center;
			@include transitionMedium;
		}
		.dz-title,
		.dz-title a{
			color:#000;
			font-size: 24px;
			font-weight: 600;
			@include respond('tab-port'){
				font-size: 24px;
			}
		}
		p {
			font-size: 15px;
			color: #666666;
			@include transitionMedium;
		}
		.read-more {
			color: var(--primary);
			letter-spacing: 0.1em;
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			font-family: var(--font-family-title);
			@include transitionMedium;
		}
		&:hover,
		&.active{
			color: var(--theme-text-color);

			transform: translateY(-10px);
			.dz-title a,
			p{
				color: rgba(255, 255, 255, 0.7);
			}
			.dz-title a{
				color: #fff;
			}
			
			.read-more{
				color: var(--primary);
			}
			.icon-cell{
				display: inline-block;
				i{
					animation: move-lr 1.2s linear;
					color: var(--primary);
				}
				img{
					filter: brightness(0);
				}
			}
			.btn {
				background-color:#fff;
				color: #000;
			}
			
			&:after{
				left: 0;
			    width: 100%;
			    transform-origin: left center;
			}
		}
	}

	&.style-5{
		padding: 30px;
		@include transitionMedium;
		color:#000;
		z-index: 1;
		position: relative;
		
		.icon-bx{
			display: flex;
		}
		.icon-cell {
			display: inline-block;
			i{
				font-size: 60px;
				line-height: 1;
				display: flex;
				@include transitionMedium;
			}
			img{
				@include transitionMedium;
			}
		}
		.icon-content{
			padding-right: 60px;
			.dz-title,
			.dz-title a{
				color:#000;
				font-size: 24px;
				font-weight: 600;
				@include respond('tab-port'){
					font-size: 24px;
				}
			}
			a{

			}
			p {
				font-size: 15px;
				color: #666666;
				@include transitionMedium;
			}
			.read-more {
				color: #000;
				letter-spacing: 0.1em;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				font-family: var(--font-family-title);
				@include transitionMedium;
			}
		}
		.badge{
			position: absolute;
			top: 0;
			right: 0px;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			overflow: visible;
			clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 48%, 0% 0%);
			z-index: -1;
			@include respond('laptop'){
				
			}
			@media (max-width: 1200px){
				clip-path: polygon(80% 0%, 100% 50%, 80% 100%, 0% 100%, 0 48%, 0% 0%);

			}
			@include respond('tab-port'){
				clip-path: polygon(70% 0%, 100% 50%, 70% 100%, 0% 100%, 0 48%, 0% 0%);
			}
			span{
				font-weight: 600;
				font-size: 240px;
				line-height: 30px;
				text-transform: capitalize;
				color: var(--secondary);
			    font-family: 'Oswald', sans-serif;

				@include transitionMedium;
				
				@include respond('tab-port'){
					font-size: 180px;
					right: -10px;
				}
				@include respond('phone'){
					font-size: 150px;
				}

			}
			&::before{
				content: '';
				position: absolute;
				top: 0;
				width: 80px;
				height: 100%;
				right: 0%;
				background: #232323;
				opacity: 0.13;
				filter: blur(27px);
				transform: matrix(-1, 0, 0, 1, 0, 0);
				@include transitionMedium;
			}
		}
		
		svg{
			position: absolute;
			top: 50%;
			right: -30px;
			transform: translateY(-50%);
			@include transitionMedium;
			color: var(--secondary);
		}
		
		&:hover,
		&.active{
			svg{
				color: var(--primary);
			}
			.badge span{
				color: var(--primary);
			}
			&::before{
				background: var(--primary);
			}
			.read-more{
				color: var(--primary);
			}
		}

		@include respond('phone-land'){
			background: #fff;
			
			svg{
				display: none;
			}
			.badge{
				clip-path: none;
				height: auto;
				top: 30px;
				right: 20px;
				span{
					font-size: 80px;
					
				}
			&::before{
				display: none;
			}
			
		}
		}
	}

	&.style-6 {
		padding: 30px;
		background-color: var(--secondary);
		@include transitionMedium;
		z-index: 1;
		position: relative;
		overflow: hidden;
		
		.icon-bx{
			display: flex;
		}
		.icon-cell {
			display: inline-block;
			i{
				font-size: 60px;
				line-height: 1;
				display: flex;
				@include transitionMedium;
			}
			img{
				@include transitionMedium;
			}
		}
		.icon-content {
			overflow: visible;
		}
		&:after{
			content:"";
			position: absolute;
			display: inline-block;
			background-color: var(--secondary);
			width: 100%;
			height: 100%;
			top: 0;
			z-index: -1;
			right: 0%;
		    @include transitionMedium;
			opacity: 1;
			border: 2px solid var(--secondary);
		}
		p{
			font-size: 15px;
			@include transitionMedium;
		}
		.read-more {
			color: var(--primary);
			text-transform: uppercase;
			font-family: var(--font-family-title);
			@include transitionMedium;
		}
		&:hover,
		&.active{
			color: var(--theme-text-color);
			
			p,
			.dz-title,
			.dz-title a,
			.read-more{
				color: var(--theme-text-color);
			}
			.icon-cell {
				display: inline-block;
				animation: move-lr 1.2s linear;
				i{
					color: var(--theme-text-color)
				}
				img{
					filter: brightness(0);
				}
			}
			.btn {
				background-color:#fff;
				color: #000;
			}
			
			&:after{
				opacity: 0.8;
				border-color: var(--primary);
			}
		}
		@include respond('tab-port'){
			.icon-bx {
				img {
					width: 50px;
				}
			}
		}
		@include respond('phone'){
			padding: 25px;
			.icon-bx {
				img {
					width: 45px;
				}
			}
		}
	}

	

	&.pad-t{
		margin-top: 100px;
		@include respond('tab-land'){
			margin-top: 0;
		}
	}
}

.pad-m {
	margin-top: -86px;
	@include respond('tab-land'){
		margin-top: 0;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes left-right {
	20% {
		transform: translateX(-5px) rotate(-5deg);
	}
	40% {
		transform: translateX(5px) rotate(5deg);
	}
	60% {
		transform: translateX(-5px) rotate(-5deg);
	}
	80% {
		transform: translateX(5px) rotate(5deg);
	}
	100% {
		transform: translateX(-5px) rotate(-5deg);
	}
}